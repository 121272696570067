import React, { useState, useEffect } from 'react'
import {
  Flex,
  Text,
  PagePrint,
  CustomizeProductHeader,
  Loading,
} from 'components'
import * as S from './Package.styled'
import { getThemeLayout, getWatermark } from 'api/themes'
import {
  create,
  getOrderPageSets,
  getHomeLayoutPageSets,
} from 'api/orderPageSets'
import { getOrderPageDetails, updateOrderPage } from 'api/orderPages'
import { getFuneralHomeSettings } from 'api/funeralHomeSettings'
import pick from 'lodash/pick'
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil'
import {
  orderAtom,
  selectedProductsSelector,
  layoutAtom,
  productTabAtom,
  dateFormatAtom,
} from 'atoms'
import { funeralHomeDateFormat } from 'utils'
import { useErrorHandler } from 'hooks/utility/useErrorHandler'
import { updateOrderProduct } from 'api/orderProducts'
import { isEmpty } from 'lodash'

export default ({
  productInfo,
  productLineInfo,
  themeInfo,
  productsAndLayouts,
  getOrderProducts,
}) => {
  const handleError = useErrorHandler()
  const [selectedProducts, setSelectedProducts] = useRecoilState(
    selectedProductsSelector,
  )
  const [layout, setLayout] = useState('')
  const [productStatus, setProductStatus] = useState('')
  const [selectedLayout, setSelectedLayout] = useState('')
  const [allLayouts, setAllLayouts] = useState([])
  const [pageSet, setPageSet] = useState(null)
  const order = useRecoilValue(orderAtom)
  const storedProductTab = useRecoilValue(productTabAtom)
  const setDateFormat = useSetRecoilState(dateFormatAtom)
  const [pagesData, setPagesData] = useState([])
  const [oldProduct, setOldProduct] = useState(false)
  const [themeLayout, setThemeLayout] = useState(null)
  const [storedLayout, setStoredLayout] = useRecoilState(layoutAtom)
  const [isLoading, setIsLoading] = useState(true)
  const [concatenatedPages, setConcatenatedPages] = useState(0)
  const isConcatenatedPages = concatenatedPages > 0

  const getSettings = async () => {
    try {
      const { data } = await getFuneralHomeSettings()
      const date = funeralHomeDateFormat(data?.date_format)
      setDateFormat(date)
    } catch (error) {
      handleError(error?.response?.data?.message || error.message, error)
    }
  }

  const getPageData = async (pages) => {
    try {
      //sort the pages
      let pagesJson = []
      const fetchedWatermarks = await getThemeLayouts()
      pages?.map((page) => {
        if (!page.json_template) return page
        page.json_template.product.orderPageId =
          page?.json_template?.product && page.id
        page?.json_template && pagesJson.push(page.json_template)

        return page
      })

      pagesJson.sort((a, b) => {
        return a.pageProperties.pageNumber > b.pageProperties.pageNumber
          ? 1
          : -1
      })

      let matchedMarks
      //if the json template has watermarks and is page 1 (I think interior pages do not have watermarks 🤔)
      pagesJson = pagesJson.map((page) => {
        if (
          page?.product?.watermarks?.length > 0 &&
          page.pageProperties.pageNumber === 1
        ) {
          //check to see if the currently set watermarks match the incoming watermarks
          //we want to see if these are the same watermarks as on the currently selected theme
          //we'll want to set some values in the future to track if they replaced the watermarks (then the ids won't match but we won't want to update them)
          //TODO - create logic for handling replaced watermarks and replaced 1 watermark
          matchedMarks = fetchedWatermarks.find((mark) => {
            return page.product.watermarks.find(
              (storedMark) => storedMark.id === mark.id,
            )
          })
        }
        //if there are no matches (the watermarks are new to this package) and it's page 1, set the watermarks to be the ones fetched from the db
        if (!matchedMarks && page.pageProperties.pageNumber === 1) {
          page.product.watermarks = fetchedWatermarks
          //if they never go into the editor we need to add the watermarks to the json so it'll print on the pdf, but we dont need to wait for that.
          const payload = { json_template: JSON.stringify(page) }

          updateOrderPage(page.product.orderPageId, payload)
        }
        return page
      })

      setPagesData(pagesJson)
      pagesJson.length < 1 && setOldProduct(true)
      setIsLoading(false)
    } catch (error) {
      handleError(error?.response?.data?.message || error.message, error)
    }
  }

  const getPageSets = async () => {
    try {
      let fetchedPageSet
      //get existing page sets
      const { data: orderPageSetsData } = await getOrderPageSets(
        selectedLayout.id,
      )
      let defaultSet = orderPageSetsData

      //if the page set has already been attached to the order, we just need to make sure we have the default if there is more than one
      if (defaultSet.length > 1)
        defaultSet = defaultSet.filter((item) => item.default)

      if (defaultSet.length < 1) {
        //if there are none, we get the funeral home's page set options for the layout
        const { data: homePageSetsDataWithTheme } = await getHomeLayoutPageSets(
          selectedLayout.layout_id,
          themeInfo.id,
        )
        let res = homePageSetsDataWithTheme

        if (isEmpty(homePageSetsDataWithTheme)) {
          const { data: homePageSetsData } = await getHomeLayoutPageSets(
            selectedLayout.layout_id,
          )
          res = homePageSetsData
        }
        //if there is only one (the admin default) set that to be the page set

        if (res.length === 1) {
          fetchedPageSet = res[0]
        } else {
          //if more than one, filter for those marked default (there can be only 2, the first will be the admin default, the second might be the custom)
          let defaultSet = res.filter((item) => item.default)
          //if the filter finds two, pick the custom one

          // TODO: WE NEED TO REFACTOR THIS
          if (defaultSet.length > 1)
            defaultSet = defaultSet.filter(
              (item) => item.home_template && item.default,
            )
          if (defaultSet.length > 0) {
            fetchedPageSet = defaultSet[0]
          } else {
            fetchedPageSet = res.filter((item) => item.default)[0]
          }
        }
        //now we have the base page sets, we need to create versions to attach to the order
        const payload = pick(fetchedPageSet, [
          'default',
          'home_template',
          'layout_id',
          'name',
        ])
        payload.page_set_id = fetchedPageSet.id
        payload.order_layout_id = selectedLayout.id
        //this will also create the 'page' objects and return both data: {page_set: {}, pages: []}
        // This creates de orderPageSet
        const { data: newPageSetData } = await create(payload)
        setPageSet(newPageSetData.page_set)
        getPageData(newPageSetData.pages)
      } else {
        setPageSet(defaultSet[0])
        //then we need to fetch the pages seperately
        const { data: pagesData } = await getOrderPageDetails(defaultSet[0].id)
        getPageData(pagesData)
      }
    } catch (error) {
      handleError(error?.response?.data?.message || error.message, error)
    }
  }

  const getThemeLayouts = async () => {
    try {
      const { data } = await getThemeLayout(
        themeInfo.theme_id || themeInfo.id,
        selectedLayout.layout_id,
      )
      if (data.length === 0) return
      setThemeLayout(data[0])

      const fetchedWatermarks = []
      if (data?.[0].first_watermark) {
        const { data: firstWatermark } = await getWatermark(
          data[0].first_watermark,
        )
        //set the required data fields and do the math to translate them to 96dpi
        //TODO: once the old site is decommissioned we can run a script to do this globally
        firstWatermark.type = 'watermark'
        firstWatermark.y = (firstWatermark.top / 72) * 96
        firstWatermark.x = (firstWatermark.left / 72) * 96
        firstWatermark.height = (firstWatermark.height / 72) * 96
        firstWatermark.width = (firstWatermark.width / 72) * 96
        firstWatermark.z = 1
        //add the watermark to the array of watermarks
        fetchedWatermarks.push(firstWatermark)
      }
      if (data?.[0].second_watermark) {
        const { data: secondWatermark } = await getWatermark(
          data[0].second_watermark,
        )
        secondWatermark.type = 'watermark'
        secondWatermark.y = (secondWatermark.top / 72) * 96
        secondWatermark.x = (secondWatermark.left / 72) * 96
        secondWatermark.z = 1
        secondWatermark.height = (secondWatermark.height / 72) * 96
        secondWatermark.width = (secondWatermark.width / 72) * 96

        fetchedWatermarks.push(secondWatermark)
      }

      return fetchedWatermarks
    } catch (error) {
      handleError(error?.response?.data?.message || error.message, error)
    }
  }

  const setLayoutAndPersist = (layout) => {
    setLayout(layout)
    setStoredLayout(layout)
  }

  const setAndUpdateProductStatus = async (_productStatus) => {
    if (!productInfo?.id) return
    try {
      await updateOrderProduct(productInfo.id, { status: _productStatus })
      getOrderProducts()
    } catch (error) {
      handleError(error?.response?.data?.message || error.message, error)
    }
  }

  const determineLayout = (
    storedProductTab,
    storedLayout,
    productsAndLayouts,
  ) => {
    const storedProduct = productsAndLayouts.find(
      (product) => product.name === storedProductTab,
    )
    if (storedProduct) {
      const layoutExists = storedProduct.layouts.find(
        (layout) => layout.id === storedLayout,
      )
      if (layoutExists) {
        setLayout(storedLayout)
      } else {
        setLayout(storedProduct.layouts[0].id)
        setStoredLayout(storedProduct.layouts[0].id)
      }
    } else {
      setLayout(productInfo.layouts[0].id)
      setStoredLayout(productInfo.layouts[0].id)
    }
  }

  useEffect(() => {
    setSelectedLayout(allLayouts.find((item) => item.id === layout))
    //eslint-disable-next-line
  }, [allLayouts, layout, setLayout])

  useEffect(() => {
    storedProductTab &&
      determineLayout(storedProductTab, storedLayout, productsAndLayouts)
    //eslint-disable-next-line
  }, [storedProductTab, productsAndLayouts])

  useEffect(() => {
    selectedLayout?.id && themeInfo?.id && getPageSets()
    //eslint-disable-next-line
  }, [selectedLayout, themeInfo])

  useEffect(() => {
    if (!productInfo?.id) return
    setAllLayouts(productInfo.layouts)
    setProductStatus(productInfo.status)
  }, [productInfo])

  useEffect(() => {
    let concatPages = pagesData[0]?.pageProperties?.concatenatedPages
    concatPages && setConcatenatedPages(concatPages)
  }, [pagesData])

  useEffect(() => {
    getSettings()
    //eslint-disable-next-line
  }, [])

  return (
    <Flex column>
      <CustomizeProductHeader
        order={order}
        productInfo={productInfo}
        productLineInfo={productLineInfo}
        themeInfo={themeInfo}
        selectedLayout={[selectedLayout, setSelectedLayout]}
        selectedProducts={[selectedProducts, setSelectedProducts]}
        pageSet={pageSet}
        allLayouts={allLayouts}
        layout={[layout, setLayoutAndPersist]}
        productStatus={[productStatus, setAndUpdateProductStatus]}
        oldProduct={!isLoading && pagesData?.length < 1}
        isLoading={isLoading}
        getOrderProducts={getOrderProducts}
      />

      <S.RenderContainer
        column
        border="1px solid"
        borderColor="gray4"
        radius="4px"
        margin="0 auto 40px auto"
        maxHeight="65vh"
        height="650px"
        width="1000px"
        maxWidth="70vw"
        background="gray5"
        position="relative"
        justify={isLoading && 'center'}
        align={isLoading && 'center'}
      >
        <Loading isLoading={isLoading}>
          {pagesData?.length > 0 && (
            <S.TransformWrapper>
              {!isConcatenatedPages ? (
                pagesData.map((page, index) => (
                  <PagePrint
                    key={index}
                    page={page}
                    theme={themeInfo}
                    themeLayout={themeLayout}
                    index={index}
                    margin={index === 0 ? '24px 0 24px 0' : '0 0 24px 0'}
                    print
                  />
                ))
              ) : (
                <PagePrint
                  key={pagesData[0]?.pageProperties?.page_set_id}
                  page={pagesData[0]}
                  secondPage={pagesData[1]}
                  theme={themeInfo}
                  themeLayout={themeLayout}
                  index={pagesData[0]?.pageProperties?.page_set_id}
                  margin={'24px 0 24px 0'}
                  print
                />
              )}
            </S.TransformWrapper>
          )}
        </Loading>
        {oldProduct && (
          <S.NoProduct column justify="center">
            <Text as="h3" margin="20px">
              This package does not have any products ready for the NEW
              Director's Print Suite.
            </Text>
            <Text margin="20px">Please create a new print package.</Text>
          </S.NoProduct>
        )}
      </S.RenderContainer>
    </Flex>
  )
}
