import React from 'react'
import { Flex } from 'components'
import { ToggleSwitch } from 'components/global/ToggleSwitch/ToggleSwitch'
import { useRecoilState } from 'recoil'
import { showRulerAtom, insetRulerAtom } from 'atoms'

export default () => {
  const [showRuler, setShowRuler] = useRecoilState(showRulerAtom)
  const [insetRuler, setInsetRuler] = useRecoilState(insetRulerAtom)

  return (
    <Flex column gap="12px" padding="0px 24px">
      <ToggleSwitch
        value={[showRuler, setShowRuler]}
        label="Show ruler"
        labelRight
      />
      <ToggleSwitch
        value={[insetRuler, setInsetRuler]}
        label="Inset ruler"
        labelRight
        disabled={!showRuler}
      />
    </Flex>
  )
}
