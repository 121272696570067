import React, { useState, useEffect } from 'react'
import * as S from './PdfRender.styled'
import { axios } from 'api'
import { PagePrint } from 'components'
import { funeralHomeDateFormat, sanitizeCaseDates } from 'utils'
import { caseSelector, dateFormatAtom, fhLogoAtom } from 'atoms'
import { useSetRecoilState } from 'recoil'
import { useErrorHandler } from 'hooks/utility/useErrorHandler'

export default ({ children, ...props }) => {
  const handleError = useErrorHandler()
  const [pages, setPages] = useState([])
  const [data, setData] = useState({})
  const setFHLogo = useSetRecoilState(fhLogoAtom)
  const setDateFormat = useSetRecoilState(dateFormatAtom)
  const setCase = useSetRecoilState(caseSelector)
  const [concatenatedPages, setConcatenatedPages] = useState(0)

  const { proof } = props

  const sendRequest = async () => {
    try {
      const { data } = await axios.get(`pdfPrint`)
      data.case = sanitizeCaseDates(data.case)

      setFHLogo(data?.settings?.logo)

      //data also includes case and theme
      const pageData = []
      data.pages.map((page) => {
        pageData.push(page.json_template)

        const format = funeralHomeDateFormat(data.settings.date_format)
        setDateFormat(format)

        return page
      })
      pageData.sort((a, b) => {
        return a.pageProperties.pageNumber > b.pageProperties.pageNumber
          ? 1
          : -1
      })
      setPages(pageData)
      setData(data)
      setCase(data.case)
      let concatPages = pageData[0]?.pageProperties?.concatenatedPages
      concatPages && setConcatenatedPages(concatPages)
    } catch (error) {
      handleError(error?.response?.data?.message || error.message, error)
    }
  }

  const removeFeedbackTab = () => {
    const addStyle = (styleString) => {
      const style = document.createElement('style')
      style.textContent = styleString
      document.head.append(style)
    }

    addStyle(`
      #bugmuncher_button {
        opacity: 0;
        pointer-events: none;
      }
    `)
  }

  useEffect(() => {
    sendRequest()
    removeFeedbackTab()
    //eslint-disable-next-line
  }, [])

  return (
    <S.Div className="divWrap">
      {!concatenatedPages > 0 ? (
        pages?.length > 0 &&
        pages.map((page, index) => (
          <PagePrint
            key={index}
            page={page}
            theme={data.theme}
            pdfRender
            themeLayout={data.theme_layout?.[0]}
            proof={proof}
          />
        ))
      ) : (
        <PagePrint
          key={pages[0]?.pageProperties?.page_set_id}
          page={pages[0]}
          secondPage={pages[1]}
          theme={data.theme}
          themeLayout={data.theme_layout?.[0]}
          proof={proof}
        />
      )}
    </S.Div>
  )
}
