import { caseDetailSpan } from '.'
import { replaceInitialValuesAndDates } from 'utils'

export default (editor, caseDetails, dateFormat) => {
  const content = `
    <p style="text-align: center;" data-mce-style="text-align: center;">
      In Memory of
    </p>
    <p style="text-align: center;" data-mce-style="text-align: center;">
      ${caseDetailSpan(`name_of_deceased`)}
    </p>
    <p></p>
    <p  style="text-align: center;" data-mce-style="text-align: center;">
      Date of Birth
    </p>
    <p style="text-align: center;" data-mce-style="text-align: center;">
      ${caseDetailSpan(`date_of_birth`)}
    </p>
    <p></p>
    <p style="text-align: center;" data-mce-style="text-align: center;">
      Date of Death
    </p>
    <p style="text-align: center;" data-mce-style="text-align: center;">
      ${caseDetailSpan(`date_of_death`)}
    </p>
    <p></p>
    <p style="text-align: center;" data-mce-style="text-align: center;">
      Service
    </p>
    <p style="text-align: center;" data-mce-style="text-align: center;">
      ${caseDetailSpan(`caseDetails.services[0].location`)},
      ${caseDetailSpan(`caseDetails.services[0].city`)}
      ${caseDetailSpan(`caseDetails.services[0].state`)}
    </p>
    <p style="text-align: center;" data-mce-style="text-align: center;">
      ${caseDetailSpan(`caseDetails.services[0].date`)}
    </p>
    <p style="text-align: center;" data-mce-style="text-align: center;">
      ${caseDetailSpan(`caseDetails.services[0].time`)}
    </p>
    <p></p>
    <p style="text-align: center;" data-mce-style="text-align: center;">
      Officiating
    </p>
    <p style="text-align: center;" data-mce-style="text-align: center;">
      ${caseDetailSpan(`caseDetails.clergy[0]`)}
    </p>
    <p></p>
    <p style="text-align: center;" data-mce-style="text-align: center;">
      Place of Interment
    </p>
    <p style="text-align: center;" data-mce-style="text-align: center;">
    ${caseDetailSpan(`caseDetails.interment_place`)}
    </p>
    `

  return [
    {
      type: 'menuitem',
      text: 'General Template',
      onAction: () =>
        editor.execCommand(
          'mceInsertTemplate',
          false,
          replaceInitialValuesAndDates(content, caseDetails, dateFormat),
        ),
    },
  ]
}
