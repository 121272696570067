import {
  loadLocalPlugins,
  insertDetailsMenu,
  insertTemplatesMenu,
  insertCaseMenu,
} from './'
import {
  customButtons,
  createCustomButtons,
  applyStylingToNonEditable,
} from 'utils'

export default (editor, caseDetails, dateFormat, funeralHomeDetails) => [
  loadLocalPlugins(editor),
  insertDetailsMenu(editor, caseDetails, dateFormat, funeralHomeDetails),
  insertTemplatesMenu(editor, caseDetails, dateFormat),
  createCustomButtons(editor, customButtons),
  applyStylingToNonEditable(editor),
  insertCaseMenu(editor),
]
