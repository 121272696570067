import { useState } from 'react'
import { caseAtom } from 'atoms'
import { useRecoilValue } from 'recoil'
import {
  getCaseMemorials,
  createCaseMemorial as createCaseMemorialApi,
  archiveMemorial as archiveMemorialApi,
} from 'api/caseMemorials'
import { useErrorHandler } from 'hooks/utility/useErrorHandler'

export default () => {
  const handleError = useErrorHandler()
  const _case = useRecoilValue(caseAtom)
  const [memorials, setMemorials] = useState([])
  const [memorial, setMemorial] = useState('')
  const [memorialError, setMemorialError] = useState('')
  const [submitter, setSubmitter] = useState('')
  const [submitterError, setSubmitterError] = useState('')

  const getMemorials = async () => {
    try {
      const { data: memorials } = await getCaseMemorials(_case.id)
      if (memorials) setMemorials(memorials)
    } catch (error) {
      handleError(error?.response?.data?.message || error.message, error)
    }
  }

  const createCaseMemorial = async () => {
    setMemorialError(memorial ? '' : 'Memorial is required')
    setSubmitterError(submitter ? '' : 'Shared by is required')
    if (!memorial || !submitter) return

    try {
      setMemorialError('')
      setSubmitterError('')

      const payload = {
        case_id: _case.id,
        memorial,
        submitter_name: submitter,
      }

      const { data } = await createCaseMemorialApi(payload)

      if (data) {
        setMemorial('')
        setSubmitter('')
        getMemorials()
      }

      return data
    } catch (error) {
      handleError(error?.response?.data?.message || error.message, error)
    }
  }

  const archiveMemorial = async (id) => {
    try {
      const { data } = await archiveMemorialApi(id)
      if (data) getMemorials()
    } catch (error) {
      handleError(error?.response?.data?.message || error.message, error)
    }
  }

  return {
    _case,
    getMemorials,
    createCaseMemorial,
    archiveMemorial,
    memorials,
    memorial,
    setMemorial,
    memorialError,
    submitter,
    setSubmitter,
    submitterError,
  }
}
