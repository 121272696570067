import { axios } from 'api'

export const create = async (payload) => {
  try {
    const response = await axios.post(`createPageSet`, payload)
    return response
  } catch (error) {
    throw error
  }
}

export const update = async (pageset_id, payload) => {
  try {
    const response = await axios.patch(`pageSets/${pageset_id}`, payload)
    return response
  } catch (error) {
    throw error
  }
}

export const archive = async (pageset_id) => {
  try {
    const response = await axios.patch(`archivePageSet/${pageset_id}`)
    return response
  } catch (error) {
    throw error
  }
}

// NOTE: This endpoint is not used
export const getByLayoutId = async (layout_id) => {
  try {
    const response = await axios.get(`pageSets?layout_id=${layout_id}`)
    return response
  } catch (error) {
    throw error
  }
}

export const getCustomPageSets = async (layout_id, theme_id) => {
  try {
    const response = await axios.get(
      `pageSets?layout_id=${layout_id}&themeId=${theme_id}&custom_pages=true`,
    )
    return response
  } catch (error) {
    throw error
  }
}
