import chunk from 'lodash/chunk'

const tableData = ({ value, styles }) => {
  const { attributes } = styles
  const style = attributes?.style || ''

  const cellContent = `<span class="mceNonEditable" ${style}>${value}</span>`

  return `
    <td 
      style="width: 50%; 
      text-align: center" 
      data-mce-style="width: 50%; text-align: center;"
    >
      ${cellContent}
    </td>
  `
}

const tableRow = ({ chunk, styles }) => {
  const item = chunk.map((value) => tableData({ value, styles })).join('')

  return `<tr>${item}</tr>`
}

export default ({ path, items, columns = 2, styles = {} }) => {
  const chunked = chunk(items, columns)
  const { attributes } = styles
  const dataStyle = attributes?.styleData || ''

  return `
    <div data-case-detail-table="${path}" class="mceNonEditable" ${dataStyle}>
      <table
        style="border-collapse: collapse; width: 100%"
        border="0"
        data-mce-style="border-collapse: collapse; width: 100%;"
      >
        <tbody>
          ${chunked.map((chunk) => tableRow({ chunk, styles })).join('')}
        </tbody>
      </table>
    </div>
`
}
