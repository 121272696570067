import styled from 'styled-components'
import { Flex } from 'components'

export const SlideoutMenu = styled(Flex)`
  overflow-x: hidden;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
`

export const Content = styled(Flex)`
  overflow-y: auto;
  overflow-x: hidden;

  /* this after element allows for "overflow: auto" to have margin */
  &:after {
    content: '';
    display: block;
    height: 1px;
    width: 100%;
  }
`
