import { useState } from 'react'
import { sendShareInvite } from 'api/users'
import { useSetToast } from 'hooks'
import { useErrorHandler } from 'hooks/utility/useErrorHandler'

export default () => {
  const handleError = useErrorHandler()
  const setToast = useSetToast()
  const [name, setName] = useState('')
  const [nameError, setNameError] = useState('')
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const [expDate, setExpDate] = useState('')
  const [expDateError, setExpDateError] = useState('')
  const [numOfImages, setNumOfImages] = useState(25)

  const submit = async (id, deceased, twoWeekLimit) => {
    try {
      setNameError(name ? '' : 'Name is required')
      setEmailError(email ? '' : 'Email is required')
      setExpDateError(expDate ? '' : 'Expiration date is required')

      if (!name || !email || !expDate) return

      const expDateLessThanTwoWeeks = expDate
        ? expDate.getTime() < twoWeekLimit.getTime()
        : null

      setExpDateError(
        expDateLessThanTwoWeeks ? '' : 'Expiration date past limit',
      )

      if (!expDateLessThanTwoWeeks) return

      setNameError('')
      setEmailError('')
      setExpDateError('')

      const payload = {
        email,
        case_id: id,
        name_of_deceased: deceased,
        recipient: name,
        number_allowed: numOfImages,
        expiration_date: expDate,
      }

      const { data } = await sendShareInvite(payload)

      if (data.emailSent) {
        setName('')
        setEmail('')
        setExpDate('')
        setToast({ text: 'Invite Sent!' })
        return
      }
    } catch (error) {
      handleError(error?.response?.data?.message || error.message, error)
    }
  }

  return {
    name,
    setName,
    nameError,
    setNameError,
    email,
    setEmail,
    emailError,
    setEmailError,
    expDate,
    setExpDate,
    expDateError,
    setExpDateError,
    numOfImages,
    setNumOfImages,
    submit,
  }
}
