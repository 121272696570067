export default (key) => {
  const unwantedKeys = [
    'id',
    'funeral_home_id',
    'created_at',
    'updated_at',
    'editable_case_id',
    'archived',
  ]

  return !unwantedKeys.includes(key)
}
