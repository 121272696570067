import React, { useRef } from 'react'
import { Flex, TinyMCE, Text } from 'components'
import { useRecoilState, useRecoilValue } from 'recoil'
import { caseAtom, editCaseFieldSelector } from 'atoms'

export default ({ label, name }) => {
  const _case = useRecoilValue(caseAtom)
  const [textareaValue, setTextareaValue] = useRecoilState(
    editCaseFieldSelector({ name: name, path: 'value' }),
  )
  const editorRef = useRef(null)

  const handleBlur = () => {
    if (editorRef.current) {
      let content = editorRef.current.getContent()
      if (!content) content = '<p></p>'
      setTextareaValue(content)
    }
  }

  return (
    <Flex column full padding="0 40px 0 0">
      <Flex column align="flex-start">
        <Text as="h3" transform="capitalize">
          {label}
        </Text>
        <Flex
          border="1px solid"
          borderColor="gray4"
          height="500px"
          margin="0 0 25px 0"
          overflow="auto"
          padding="7px"
          onBlur={handleBlur}
          background="lockedWhite"
          id={`${name}-textarea`}
          width="100%"
        >
          <TinyMCE
            setRef={(ref) => (editorRef.current = ref)}
            value={textareaValue}
            caseDetails={_case}
            init={{
              toolbar: false,
              extended_valid_elements:
                'span[class|type|data-case-detail|data-style],p[class|type|data-case-detail|data-style],div[data-style]',
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}
