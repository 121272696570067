export default [
  {
    identifier: 'testCustomButton',
    config: (editor) => ({
      text: 'Test Custom Button Text',
      tooltip: 'Test Custom Button Tooltip',
      icon: 'warning',
      onAction: () => console.log(editor),
    }),
  },
  {
    identifier: 'testCustomButton2',
    config: (editor) => ({
      text: 'Test Custom Button Text 2',
      tooltip: 'Test Custom Button Tooltip 2',
      icon: 'warning',
      onAction: () => console.log(editor),
    }),
  },
  {
    identifier: 'customImageButton',
    config: (editor) => ({
      tooltip: 'Add Image',
      icon: 'image',
      onAction: () =>
        editor.insertContent(
          `<img src="${process.env.REACT_APP_S3BUCKET_CASE_IMAGES}4o4m8eimage.jpeg" />`,
        ),
    }),
  },
  {
    identifier: 'customSelectAll',
    config: (editor) => ({
      text: 'Select All',
      tooltip: 'Select All',
      icon: 'select-all',
      onAction: () => editor.execCommand('SelectAll'),
    }),
  },
]
