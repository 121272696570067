import React from 'react'
import { Flex, Button, Input, TinyMCE } from 'components'

export default ({
  value,
  reference: [reference, setReference],
  TinyMCERef,
  cancel,
  save,
}) => {
  return (
    <Flex column full>
      <Flex
        border="1px solid"
        borderColor="gray4"
        radius="2px"
        minHeight="200px"
        margin="0 0 12px 0"
        full
        background="lockedWhite"
      >
        <TinyMCE
          placeholder="Type text here"
          value={value}
          setRef={(ref) => (TinyMCERef.current = ref)}
        />
      </Flex>
      <Input
        margin="0 0 12px 0"
        label="Reference"
        value={[reference, setReference]}
      />
      <Flex>
        <Button onClick={() => cancel?.()} margin="0 12px 0 0">
          Cancel
        </Button>
        <Button primary onClick={() => save?.()}>
          Save
        </Button>
      </Flex>
    </Flex>
  )
}
