import React from 'react'
import {
  createDefaultImageReader,
  createDefaultImageWriter,
  setPlugins,
  plugin_crop,
  plugin_filter,
  plugin_finetune,
  plugin_annotate,
  plugin_resize,
  plugin_frame,
  locale_en_gb,
  plugin_crop_locale_en_gb,
  plugin_filter_locale_en_gb,
  plugin_finetune_locale_en_gb,
  plugin_annotate_locale_en_gb,
  plugin_resize_locale_en_gb,
  plugin_frame_locale_en_gb,
  markup_editor_locale_en_gb,
  markup_editor_defaults,
  plugin_finetune_defaults,
  plugin_filter_defaults,
  plugin_frame_defaults,
  createMarkupEditorToolbar,
  createDefaultShapePreprocessor,
} from 'pintura'
import { PinturaEditor } from 'react-pintura'
import 'pintura/pintura.css'

import * as S from './Pintura.styled'
import { editBackgroundImageAtom } from 'atoms'
import usePintura from './usePintura'
import { useRecoilValue } from 'recoil'

setPlugins(
  plugin_crop,
  plugin_filter,
  plugin_finetune,
  plugin_annotate,
  plugin_resize,
  plugin_frame,
)

// Create our editor configuration
const editorConfig = {
  // This will read the image data (required)
  imageReader: createDefaultImageReader(),

  // This will write the output image
  imageWriter: createDefaultImageWriter(),

  // The markup editor default options, tools, shape style controls
  ...markup_editor_defaults,

  // The finetune util controls
  ...plugin_finetune_defaults,

  ...plugin_frame_defaults,

  ...plugin_filter_defaults,

  // This handles complex shapes like arrows / frames
  shapePreprocessor: createDefaultShapePreprocessor(),

  // This allows total freedom in cropping
  imageCropAspectRatio: undefined,
  cropImageSelectionCornerStyle: 'hook',

  // The icons and labels to use in the user interface (required)
  locale: {
    ...locale_en_gb,
    ...plugin_crop_locale_en_gb,
    ...plugin_filter_locale_en_gb,
    ...plugin_finetune_locale_en_gb,
    ...plugin_annotate_locale_en_gb,
    ...plugin_resize_locale_en_gb,
    ...plugin_frame_locale_en_gb,
    ...markup_editor_locale_en_gb,
  },

  // Specific tools for annotate (we don't wont text)
  markupEditorToolbar: createMarkupEditorToolbar([
    'sharpie',
    'eraser',
    'line',
    'arrow',
    'rectangle',
    'ellipse',
  ]),
}

export default ({ update }) => {
  const editBackground = useRecoilValue(editBackgroundImageAtom)
  const { url, edits, handleFailure, handleProcessing, handleDoneEditing } =
    usePintura(update, editBackground)

  return (
    <S.Wrapper
      width="70vw"
      maxWidth="1000px"
      height="100vh"
      minHeight="700px"
      maxHeight="800px"
      padding="12px 36px 36px 36px"
    >
      {url && editorConfig && (
        <PinturaEditor
          {...editorConfig}
          src={`${url}?CORSRequest=true`}
          imageState={edits}
          onProcesserror={handleFailure}
          onProcessstart={handleProcessing}
          onProcess={handleDoneEditing}
        ></PinturaEditor>
      )}
    </S.Wrapper>
  )
}
