import React, { useState, useEffect } from 'react'
import {
  Button,
  Flex,
  AnimatedHeight,
  MemorialForm,
  MemorialCard,
} from 'components'
import { useMemorials } from 'hooks'
import { default as isEmpty } from 'lodash/isEmpty'

export default () => {
  const [formOpen, setFormOpen] = useState(false)
  const {
    _case,
    memorials,
    getMemorials,
    memorial,
    memorialError,
    setMemorial,
    submitter,
    setSubmitter,
    submitterError,
    createCaseMemorial,
    archiveMemorial,
  } = useMemorials()

  useEffect(() => {
    !isEmpty(_case) && getMemorials()
    //eslint-disable-next-line
  }, [_case])

  return (
    <Flex column margin="56px 0 0 0" center width="100%">
      <AnimatedHeight
        height={!formOpen ? 'auto' : 0}
        width="fit-content"
        duration={250}
      >
        <Button onClick={() => setFormOpen(true)} primary icon="plus">
          Add a memorial
        </Button>
      </AnimatedHeight>
      <AnimatedHeight height={formOpen ? 'auto' : 0} width="80%" duration={250}>
        <MemorialForm
          memorial={memorial}
          memorialError={memorialError}
          setMemorial={setMemorial}
          submitter={submitter}
          setSubmitter={setSubmitter}
          submitterError={submitterError}
          setFormOpen={setFormOpen}
          createCaseMemorial={createCaseMemorial}
        />
      </AnimatedHeight>
      <Flex center column margin="28px 0 80px 0" width="100%">
        {memorials?.map(({ id, memorial, submitter_name, archived }) =>
          archived ? null : (
            <MemorialCard
              key={id}
              id={id}
              memorial={memorial}
              from={submitter_name}
              isLoggedIn
              archiveMemorial={archiveMemorial}
            />
          ),
        )}
      </Flex>
    </Flex>
  )
}
