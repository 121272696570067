import { Flex } from 'components/priority/Flex/Flex.styled'
import styled, { css } from 'styled-components'
import { colors as c } from 'utils'

const height = 5000
const width = 5000

export const GrayoutZone = styled(Flex)`
  background: ${c('white', 50)};
  position: absolute;
  z-index: 50;
  pointer-events: none;
`

export const TopZone = styled(GrayoutZone)(
  () => css`
    top: -${height}px;
    left: 0;
    width: 100%;
    height: ${height}px;
  `,
)

export const RightZone = styled(GrayoutZone)(
  () => css`
    top: -${height / 2}px;
    left: 100%;
    width: ${width}px;
    height: ${height}px;
  `,
)

export const BottomZone = styled(GrayoutZone)(
  () => css`
    top: 100%;
    left: 0;
    width: 100%;
    height: ${height}px;
  `,
)

export const LeftZone = styled(GrayoutZone)(
  () => css`
    top: -${height / 2}px;
    left: -${width}px;
    width: ${width}px;
    height: ${height}px;
  `,
)
