import React, { useState, useEffect } from 'react'
import { CustomizePrint, Navigation } from 'components'
import { formatDate } from 'utils'
import { caseAtom, funeralHomeAtom } from 'atoms'
import { useRecoilState, useRecoilValue } from 'recoil'
import { themeCategories } from 'mocks'
import { isEmpty } from 'lodash'
import { getFuneralHome } from 'api/funeralHomes'

export default () => {
  const caseInfo = useRecoilValue(caseAtom)
  const [caseHeader, setCaseHeader] = useState('')
  const [fh, setFh] = useRecoilState(funeralHomeAtom)

  useEffect(() => {
    const getUserFuneralHome = async () => {
      try {
        const response = await getFuneralHome()
        return response
      } catch (error) {
        throw error
      }
    }

    if (isEmpty(fh)) {
      getUserFuneralHome()
        .then((res) => {
          setFh(res)
        })
        .catch((err) => {
          console.error(err)
        })
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    caseInfo?.name_of_deceased
      ? setCaseHeader(
          `${caseInfo?.name_of_deceased}, ${
            caseInfo?.editable_case_id ||
            formatDate(caseInfo?.date_of_death, 'PP')
          }`,
        )
      : setCaseHeader('')
  }, [caseInfo])

  return (
    <>
      <Navigation type="package" />

      <CustomizePrint caseInfo={caseHeader} categories={themeCategories} />
    </>
  )
}
