import React, { useEffect, useState } from 'react'
import { Expandable, Flex } from 'components'
import { CaseFormSection } from '..'

export default ({ saveCase, activeTab }) => {
  const [expandedBranch, setExpandedBranch] = useState(true)
  const [expandedStation, setExpandedStation] = useState('')
  const [expandedFiringParty, setExpandedFiringParty] = useState('')
  const [expandedHonors, setExpandedHonors] = useState('')

  useEffect(() => {
    setExpandedBranch(true)
    setExpandedStation(false)
    setExpandedFiringParty(false)
    setExpandedHonors(false)
  }, [activeTab])

  const saveAndContinue = (expanded) => {
    switch (expanded) {
      case 'branchOfService':
        setExpandedBranch(false)
        setExpandedStation(true)
        break
      case 'station':
        setExpandedStation(false)
        setExpandedFiringParty(true)
        break
      case 'firingParty':
        setExpandedBranch(false)
        setExpandedStation(false)
        setExpandedFiringParty(false)
        setExpandedHonors(true)
        break
      case 'honors':
        setExpandedBranch(false)
        setExpandedStation(false)
        setExpandedFiringParty(false)
        setExpandedHonors(false)
        break
      default:
        //for now collapse all
        setExpandedBranch(false)
        setExpandedStation(false)
        setExpandedFiringParty(false)
        setExpandedHonors(false)
    }
    saveCase()
  }

  return (
    <Flex column full padding="0 48px 0 0">
      <Flex column align="flex-start">
        <Expandable
          margin="0 0 32px 0"
          title="Branch of Service"
          show={[expandedBranch, setExpandedBranch]}
          full
        >
          <CaseFormSection
            formSection="branchOfService"
            saveAndContinue={saveAndContinue}
          />
        </Expandable>

        <Expandable
          margin="0 0 32px 0"
          title="Station Within US"
          show={[expandedStation, setExpandedStation]}
          full
        >
          <CaseFormSection
            formSection="station"
            saveAndContinue={saveAndContinue}
          />
        </Expandable>
        <Expandable
          margin="0 0 32px 0"
          title="Firing Party"
          show={[expandedFiringParty, setExpandedFiringParty]}
          full
        >
          <CaseFormSection
            formSection="firingParty"
            saveAndContinue={saveAndContinue}
          />
        </Expandable>
        <Expandable
          margin="0 0 32px 0"
          title="Honors"
          show={[expandedHonors, setExpandedHonors]}
          full
        >
          <CaseFormSection
            formSection="honors"
            saveAndContinue={saveAndContinue}
          />
        </Expandable>
      </Flex>
    </Flex>
  )
}
