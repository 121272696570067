export default {
  'Memorial Stationery': [
    {
      title: 'Register Book',
      category: 'Memorial Stationery',
    },
    {
      title: 'Deluxe Register Book',
      category: 'Memorial Stationery',
    },
    {
      title: 'Thank You Cards',
      category: 'Memorial Stationery',
    },
    {
      title: 'Service Records',
      category: 'Memorial Stationery',
    },
    {
      title: 'Trifolds',
      category: 'Memorial Stationery',
    },
    {
      title: 'Prayer Cards',
      category: 'Memorial Stationery',
    },
    {
      title: 'Bookmarks',
      category: 'Memorial Stationery',
    },
    {
      title: 'Grave Markers',
      category: 'Memorial Stationery',
    },
    {
      title: 'Church Bulletins',
      category: 'Memorial Stationery',
    },
    {
      title: 'Clergy Records',
      category: 'Memorial Stationery',
    },
  ],
  'Memorial Candles': [
    {
      title: 'Designer Square Candle',
      category: 'Memorial Candles',
    },
    {
      title: 'Designer Round Candle',
      category: 'Memorial Candles',
    },
    {
      title: 'Small Candle',
      category: 'Memorial Candles',
    },
    {
      title: 'Large Candle',
      category: 'Memorial Candles',
    },
  ],
  'Service Items': [
    {
      title: 'Picture Frame',
      category: 'Service Items',
    },
    {
      title: 'Directory Signs',
      category: 'Service Items',
    },
    {
      title: 'Memory Box',
      category: 'Service Items',
    },
    {
      title: 'Memorial Collage',
      category: 'Service Items',
    },
    {
      title: '16 x 20 Collage',
      category: 'Service Items',
    },
  ],
  'Dignity Direct': [
    {
      title: 'Small Memory Card',
      category: 'Dignity Direct',
    },
    {
      title: 'Small Memory Folder',
      category: 'Dignity Direct',
    },
    {
      title: 'Small Acknowledgement Card',
      category: 'Dignity Direct',
    },
    {
      title: 'Medium Memory Card',
      category: 'Dignity Direct',
    },
    {
      title: 'Medium Memory Folder',
      category: 'Dignity Direct',
    },
    {
      title: 'Medium Tribute Acknowledgement',
      category: 'Dignity Direct',
    },
    {
      title: 'Medium Signature Acknowledgement',
      category: 'Dignity Direct',
    },
    {
      title: 'Large Memory Card',
      category: 'Dignity Direct',
    },
    {
      title: 'Large Memory Folder/Booklet',
      category: 'Dignity Direct',
    },
    {
      title: 'Register Book Pages',
      category: 'Dignity Direct',
    },
    {
      title: 'Small Memory Card Horizontal',
      category: 'Dignity Direct',
    },
    {
      title: 'Medium Memory Card Horizontal',
      category: 'Dignity Direct',
    },
    {
      title: 'Large Memory Card Horizontal',
      category: 'Dignity Direct',
    },
    {
      title: 'Bookmarks',
      category: 'Dignity Direct',
    },
    {
      title: 'QR Card',
      category: 'Dignity Direct',
    },
    {
      title: 'Banner - Floor',
      category: 'Dignity Direct',
    },
    {
      title: 'Banner - Tabletop',
      category: 'Dignity Direct',
    },
  ],
}
