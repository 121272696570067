// // this utility will replace the text between elements that contain the "case-detail" attribute
// // with its relative case detail value

import cheerio from 'cheerio'
import { isEmpty } from 'lodash'
import get from 'lodash/get'
import {
  isDate,
  formatDate,
  tableTemplate,
  handleSpecialCaseDetailArrays,
} from 'utils'

export default (value, caseDetails, funeralHomeDetails, dateFormat) => {
  const $ = cheerio.load(value)

  const {
    getCaseDetail,
    extractStyle,
    extractStyleData,
    updateDeepestSpanText,
  } = utilities({
    caseDetails,
    funeralHomeDetails,
  })

  const caseDetailSpans = $('span[data-case-detail]')
  const caseDetailBlocks = $('div[data-case-detail]')
  const caseDetailTables = $('[data-case-detail-table]')

  // loop through all the spans
  caseDetailSpans.each(function () {
    const element = $(this)
    const { value, type } = getCaseDetail(element)

    let updatedValue = value
    if (type === 'empty') updatedValue = '&nbsp;'
    if (type === 'date') updatedValue = formatDate(value, dateFormat)

    updateDeepestSpanText(element, updatedValue)
  })

  // loop through all the divs, this is mostly for the obituary
  caseDetailBlocks.each(function () {
    const element = $(this)
    const { path, value } = getCaseDetail(element)

    const firstSpan = element.find('span').first()

    const childStyles = extractStyle(firstSpan)
    const blockStyles = extractStyleData(element)

    const styles = childStyles.alreadyStyled ? childStyles : blockStyles
    const { attributes } = styles

    const divTemplate = `<div class="mceNonEditable" data-case-detail="${path}" ${attributes.styleData}>${value}</div>`
    const databaseElement = $(divTemplate)

    // wrap each paragraph tag in the div with a span tag, and apply the firstSpanAttributes
    databaseElement.find('p').each(function () {
      const paragraph = $(this)
      const paragraphText = paragraph.text()

      const styledParagraphTemplate = `<p><span ${attributes.style}>${paragraphText}</span></p>`
      const plainParagraphTemplate = `<p>${paragraphText}</p>`

      const paragraphTemplate = attributes.style
        ? styledParagraphTemplate
        : plainParagraphTemplate

      const updatedParagraph = $(paragraphTemplate)

      paragraph.replaceWith(updatedParagraph)
    })

    element.replaceWith(databaseElement)
  })

  // loop through all the tables
  caseDetailTables.each(function () {
    const element = $(this)
    const { path, value } = getCaseDetail(element)

    const childStyles = extractStyle(element)
    const tableStyles = extractStyleData(element)

    const styles = childStyles.alreadyStyled ? childStyles : tableStyles

    const items = handleSpecialCaseDetailArrays({ path, items: value })

    const tableHtml = tableTemplate({
      path,
      items,
      styles,
    })

    element.replaceWith(tableHtml)
  })

  return $.html()
}

const createStyleUtility = (css) => ({
  css,
  alreadyStyled: !!css,
  attributes: {
    style: css ? `style="${css}"` : '',
    styleData: css ? `data-style="${css}"` : '',
  },
})

const utilities = ({ caseDetails, funeralHomeDetails }) => ({
  getOuterHTML: (element) => {
    var index = element.index()
    var parent = element.parent().clone()
    var child = parent.children()[index]
    parent.empty()
    parent.append(child)
    return parent.html()
  },

  getCaseDetail: (element) => {
    const caseDetailAttribute = element.attr('data-case-detail')
    const caseDetailTableAttribute = element.attr('data-case-detail-table')

    const attribute = caseDetailAttribute || caseDetailTableAttribute

    const path = attribute?.split(' ')?.[0]
    const initialText = element.text()

    const caseDetail = get(caseDetails, path)
    const funeralHomeDetail = get({ funeralHomeDetails }, path)
    const value = caseDetail || funeralHomeDetail || '&#8202;'

    const isPlaceholder = initialText.includes('{$')

    let type = 'string'

    if (isEmpty(value)) type = 'empty'
    if (isDate(value)) type = 'date'
    if (isPlaceholder) type = 'placeholder'

    return { path, value, initialText, type }
  },

  extractStyle: (element) => {
    const elementIsSpan = element.is('span')
    const elementStyle = element.attr('style')

    // see if the element given is a span and has a style attribute
    const rootElementIsStyled = elementIsSpan && elementStyle
    if (rootElementIsStyled) return createStyleUtility(elementStyle)

    // if the element is not a span, check if it has a span child
    let firstChildSpan = element.find('span').first()
    const hasChildren = firstChildSpan.length
    if (!hasChildren) createStyleUtility('')

    // drill down to the deepest span
    let deepestSpan = firstChildSpan
    while (deepestSpan.find('span').length > 0) {
      deepestSpan = deepestSpan.find('span').first()
    }

    // return the styling of the deepest span
    const deepestSpanStyle = deepestSpan.attr('style') || ''
    return createStyleUtility(deepestSpanStyle)
  },

  extractStyleData: (element) => {
    const css = element.attr('data-style') || ''
    return createStyleUtility(css)
  },

  updateDeepestSpanText: (element, text) => {
    let deepestSpan = element

    // Recursively drill down to the deepest span
    while (deepestSpan.find('span').length > 0) {
      deepestSpan = deepestSpan.find('span').first()
    }

    // Replace the text content of the deepest span
    deepestSpan
      .contents()
      .filter(function () {
        return this.type === 'text'
      })
      .replaceWith(text)
  },
})
