export default (editor) => {
  editor.ui.registry.addMenuButton('caseMenu', {
    text: 'Change Case',
    fetch: (callback) => {
      callback([
        {
          type: 'menuitem',
          text: 'UPPERCASE',
          onAction: () =>
            editor.execCommand('mceToggleFormat', false, 'uppercase'),
        },
        {
          type: 'menuitem',
          text: 'lowercase',
          onAction: () =>
            editor.execCommand('mceToggleFormat', false, 'lowercase'),
        },
        {
          type: 'menuitem',
          text: 'No Case',
          onAction: () =>
            editor.execCommand('mceRemoveFormat', false, 'nocase'),
        },
      ])
    },
  })
}
