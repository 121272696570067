export default (editor) => {
  const $ = editor.editorManager.dom.DomQuery
  const nonEditableClass = editor.getParam(
    'noneditable_noneditable_class',
    'mceNonEditable',
  )

  // Temporarily remove the noneditable class before any content mutation
  const unlockNonEditableBlocks = () => {
    $(editor.getBody())
      .find(`*.${nonEditableClass}`)
      .each(function () {
        $(this).removeClass(nonEditableClass)
        $(this).attr('contenteditable', true) // Temporarily allow editing
      })
  }

  // Re-lock non-editable elements after content mutation is finished
  const lockNonEditableBlocks = () => {
    $(editor.getBody())
      .find(`*[data-case-detail]`)
      .each(function () {
        $(this).addClass(nonEditableClass)
        $(this).attr('contenteditable', false) // Restore non-editable state
      })
  }

  // Trigger before any command that could mutate content
  editor.on('BeforeExecCommand', ({ command }) => {
    unlockNonEditableBlocks()
  })

  // Trigger after any command is finished
  editor.on('ExecCommand', ({ command }) => {
    setTimeout(() => {
      lockNonEditableBlocks()
    }, 0) // Ensure it locks after the command is executed
  })

  // Also trigger when a node or selection is changed (e.g., font change)
  editor.on('NodeChange', (e) => {
    unlockNonEditableBlocks()
    setTimeout(() => {
      lockNonEditableBlocks()
    }, 0)
  })

  // On initialization, lock the blocks initially
  editor.on('init', () => {
    lockNonEditableBlocks()
  })
}
