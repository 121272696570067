import React, { useState, useRef } from 'react'
import { CustomVerseEditor } from '.'
import { updateCustomVerse } from 'api/customVerses'
import { useErrorHandler } from 'hooks/utility/useErrorHandler'

export default ({ verse, setEditing, reload }) => {
  const handleError = useErrorHandler()
  const TinyMCERef = useRef(null)
  const [editReference, setEditReference] = useState(verse.reference)

  const patchVerse = async () => {
    try {
      const content = TinyMCERef?.current?.getContent?.()
      const updatedVerse = {
        html: content,
        html_for_render: content,
        reference: editReference,
      }
      await updateCustomVerse(verse.id, updatedVerse)
      setEditing(false)
      reload()
    } catch (error) {
      handleError(error?.response?.data?.message || error.message, error)
    }
  }

  return (
    <CustomVerseEditor
      TinyMCERef={TinyMCERef}
      value={verse.html}
      reference={[editReference, setEditReference]}
      cancel={() => setEditing(false)}
      save={() => patchVerse()}
    />
  )
}
