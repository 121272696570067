import { generateDetails } from 'utils'

export default (editor, caseDetails, dateFormat, funeralHomeDetails) => {
  editor.ui.registry.addMenuButton('customDetails', {
    text: 'Insert detail',
    fetch: (callback) =>
      callback(
        generateDetails(editor, caseDetails, dateFormat, funeralHomeDetails),
      ),
  })
}
