import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import {
  CustomizePrint,
  Navigation,
  ProductLine,
  Products,
  Theme,
} from 'components'
import { formatDate } from 'utils'
import { themesAtom, productsAtom, caseAtom } from 'atoms'
import { useRecoilValue } from 'recoil'
import { productsCategories, themeCategories } from 'mocks'

export default () => {
  const { pathname: location } = useLocation()
  const [, , , slug] = location.split('/')
  const themes = useRecoilValue(themesAtom)
  const products = useRecoilValue(productsAtom)
  const caseInfo = useRecoilValue(caseAtom)
  const [caseHeader, setCaseHeader] = useState('')

  useEffect(() => {
    caseInfo?.name_of_deceased
      ? setCaseHeader(
          `${caseInfo?.name_of_deceased}, ${
            caseInfo?.editable_case_id ||
            formatDate(caseInfo?.date_of_death, 'PP')
          }`,
        )
      : setCaseHeader('')
  }, [caseInfo])

  return (
    <>
      <Navigation type="package" />
      {!slug && <ProductLine caseInfo={caseHeader} />}
      {slug === 'products' && (
        <Products
          caseInfo={caseHeader}
          categories={productsCategories}
          products={products}
        />
      )}
      {slug === 'theme' && (
        <Theme caseInfo={caseHeader} themes={themes || themeCategories} />
      )}
      {slug === 'customize' && (
        <CustomizePrint caseInfo={caseHeader} categories={themeCategories} />
      )}
    </>
  )
}
