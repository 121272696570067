import { css } from 'styled-components'
import { featheredShape } from 'styles'

const feather = ({ edits, width, height }) => css`
  mask: url(${`"${featheredShape(edits, width, height)}"`});
  background-size: 100% auto;
  mask-size: 100% 100%;
  border-radius: 0;
  border: none;
`

export default (edits) => edits?.feather && feather
