import React, { useEffect, useState } from 'react'
import { uploadOptimizedCaseImage, deleteCaseImage } from 'api/cases'
import { Expandable, Flex, FileDropzone, Button } from 'components'
import { PersonalImageCard } from './'
import { useRecoilState } from 'recoil'
import { caseValuesAtom } from 'atoms'
import Memorials from './Memorials'
import SendInvite from './SendInvite'
import { useSetToast } from 'hooks'
import optimizeImage from 'utils/editor/optimizeImage'
import { useErrorHandler } from 'hooks/utility/useErrorHandler'

export default ({
  saveCase,
  case_id,
  name_of_deceased,
  setActiveTab,
  activeTab,
}) => {
  const handleError = useErrorHandler()
  const [personalImages, setPersonalImages] = useRecoilState(
    caseValuesAtom('personal_images'),
  )

  const [expandedPersonalImages, setExpandedPersonalImages] = useState(true)
  const [expandedMemorials, setExpandedMemorials] = useState(false)
  const [expandedSendInvite, setExpandedSendInvite] = useState(false)
  const [imagesLoading, setImagesLoading] = useState(false)
  const setToast = useSetToast()

  const removeArrayInput = async (index) => {
    try {
      //this endpoint apparently also deletes the image from s3, something we should do more of, lol
      const resp = await deleteCaseImage(case_id, {
        filename: personalImages.value[index],
      })
      setPersonalImages({ ...personalImages, value: resp.data })
    } catch (error) {
      handleError(error?.response?.data?.message || error.message, error)
    }
  }

  useEffect(() => {
    setExpandedPersonalImages(true)
    setExpandedMemorials(false)
    setExpandedSendInvite(false)
  }, [activeTab]) // Removed personalImages from dependencies

  const makeDefault = (index) => {
    let newArr = [...personalImages.value]
    const removedElement = newArr.splice(index, 1)
    newArr = [...removedElement, ...newArr]
    setPersonalImages({ ...personalImages, value: newArr })
  }

  const uploadCaseImages = async (filesArray) => {
    setImagesLoading(true)
    try {
      const newImages = await Promise.all(
        filesArray.map((file) => uploadCaseImage(file)),
      )
      await setPersonalImages((prevState) => {
        const updatedImages = [...prevState.value, ...newImages.flat()]
        return {
          ...prevState,
          value: updatedImages,
        }
      })
    } catch (error) {
      setToast({
        text: 'Some images failed to upload.',
      })
    } finally {
      setImagesLoading(false)
    }
  }

  const uploadCaseImage = async (fileObject) => {
    const processFile = async (fileObject) => {
      try {
        const image = new Image()
        const reader = new FileReader()
        reader.onload = (e) => {
          image.src = e.target.result
        }
        reader.onerror = (error) => {
          console.error('Error reading file:', error)
          setToast({ text: 'Failed to read the file.' })
          throw error
        }
        reader.readAsDataURL(fileObject)
        await new Promise((resolve, reject) => {
          image.onload = resolve
          image.onerror = reject
        })
        const optimizedImageDataURL = await optimizeImage(
          image,
          2560,
          1440,
          0.75,
        )
        const blob = await (await fetch(optimizedImageDataURL)).blob()
        let fd = new FormData()
        fd.append('file', blob, fileObject.name)
        const resp = await uploadOptimizedCaseImage(case_id, fd)
        setToast({
          text: resp.data ? 'Image Uploaded!' : 'Image upload failed.',
        })
        return resp.data
      } catch (error) {
        handleError(error?.response?.data?.message || error.message, error)
        throw error
      }
    }

    return processFile(fileObject)
  }

  const saveAndContinue = (expanded) => {
    switch (expanded) {
      case 'personalImages':
        setExpandedPersonalImages(false)
        setExpandedMemorials(true)
        break
      case 'memorials':
        setExpandedMemorials(false)
        setExpandedSendInvite(true)
        break
      case 'sendInvite':
        setExpandedPersonalImages(false)
        setExpandedMemorials(false)
        setExpandedSendInvite(false)
        setActiveTab('Military/Veteran honors')
        break
      default:
        setExpandedPersonalImages(false)
        setExpandedMemorials(false)
        setExpandedSendInvite(false)
    }
    saveCase()
  }

  return (
    <Flex column full padding="0 48px 0 0">
      <Flex column align="flex-start">
        <Expandable
          margin="0 0 32px 0"
          title="Images of the Deceased"
          show={[expandedPersonalImages, setExpandedPersonalImages]}
          full
        >
          <Flex full column>
            <FileDropzone
              height="200px"
              margin="0 0 24px 0"
              text={`Drag and drop file here to edit`}
              fileTypes={['.png', '.jpeg', '.jpg', '.gif']}
              allowMultiple={true}
              onDrop={(files) => uploadCaseImages(files)}
              isLoading={imagesLoading}
            />
            {personalImages?.value?.map((image, index) => (
              <PersonalImageCard
                key={`personal-image-${image}`}
                image={image}
                index={index}
                makeDefault={makeDefault}
                deleteImage={removeArrayInput}
              />
            ))}
          </Flex>
          <Flex justify="flex-end">
            <Button onClick={() => saveAndContinue('personalImages')} primary>
              Save and continue
            </Button>
          </Flex>
        </Expandable>

        <Expandable
          margin="0 0 32px 0"
          title="Memorials"
          show={[expandedMemorials, setExpandedMemorials]}
          full
        >
          <Memorials />
          <Flex justify="flex-end">
            <Button onClick={() => saveAndContinue('memorials')} primary>
              Save and continue
            </Button>
          </Flex>
        </Expandable>

        <Expandable
          margin="0 0 32px 0"
          title="Invite Family to Share Images"
          show={[expandedSendInvite, setExpandedSendInvite]}
          full
        >
          <SendInvite case_id={case_id} name_of_deceased={name_of_deceased} />
          <Flex justify="flex-end">
            <Button
              onClick={() => saveAndContinue('sendInvite')}
              primary
              margin="24px 0 0 0"
            >
              Save and continue
            </Button>
          </Flex>
        </Expandable>
      </Flex>
    </Flex>
  )
}
