import React from 'react'
import { Flex, Input, DateInput, Button } from 'components'
import { useSendInvite } from 'hooks'
import { format } from 'date-fns'
export default ({ case_id, name_of_deceased }) => {
  const {
    name,
    setName,
    nameError,
    email,
    setEmail,
    emailError,
    expDate,
    setExpDate,
    expDateError,
    numOfImages,
    setNumOfImages,
    submit,
  } = useSendInvite()

  const twoWeeksLater = new Date(Date.now() + 12096e5)

  return (
    <Flex column>
      <Flex margin="0 0 24px 0" justify="space-between">
        <Input
          label="Name"
          placeholder="Name"
          value={[name, setName]}
          error={nameError}
          width="48%"
        />
        <Input
          label="Email"
          placeholder="Email"
          value={[email, setEmail]}
          error={emailError}
          width="48%"
        />
      </Flex>
      <DateInput
        label={`Link expiration date. Max: ${format(
          twoWeeksLater,
          'MM/dd/yyyy',
        )}`}
        date={[expDate, setExpDate]}
        error={expDateError}
        margin="0 0 24px 0"
        placeholder="mm/dd/yyyy"
        width="48%"
        disabledDays={{
          before: new Date(),
          after: twoWeeksLater,
        }}
      />
      <Input
        type="number"
        label="Number of images allowed (Max 50)"
        value={[numOfImages, setNumOfImages]}
        margin="0 0 24px 0"
        width="48%"
      />
      <Flex justify="flex-end">
        <Button
          primary
          onClick={() => submit(case_id, name_of_deceased, twoWeeksLater)}
        >
          Send Invite
        </Button>
      </Flex>
    </Flex>
  )
}
