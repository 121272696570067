import React from 'react'
import { Button } from 'components'
import { usePerformLogout } from 'providers/Auth0'
import DesignerGate from 'components/auth/DesignerGate'

export default () => {
  const logout = usePerformLogout()

  return (
    <DesignerGate>
      <Button onClick={() => logout({ returnTo: window.location.origin })}>
        Logout
      </Button>
    </DesignerGate>
  )
}
