import React from 'react'
import { Button, Flex, Text, Icon, Divider } from 'components'
import { NestedObjectInputs } from './'
import { useRecoilState, useRecoilValue } from 'recoil'
import { caseValuesAtom } from 'atoms'

export default ({
  type,
  shortType,
  title,
  formField: { newObject, addCaseAndType, allowedInputs },
}) => {
  const [fieldValue, setFieldValue] = useRecoilState(caseValuesAtom(type))
  const case_id = useRecoilValue(caseValuesAtom('id'))

  if (!fieldValue.value) return null

  const addArrayInput = (shortType, case_id) => {
    let updatedArray = fieldValue.value ? [...fieldValue.value] : []
    let newObj = addCaseAndType
      ? { ...newObject, type: shortType, case_id: case_id }
      : newObject
    updatedArray.push(newObj)

    // ensure the first service added is marked as the default
    const isFirstService = updatedArray?.length === 1
    const serviceType = updatedArray?.[0]?.['type']
    const isVisitOrService =
      serviceType === 'visitation' || serviceType === 'service'

    const firstDefaultService = isFirstService && isVisitOrService
    firstDefaultService &&
      (updatedArray[0] = { ...updatedArray[0], default: true })

    setFieldValue({
      ...fieldValue,
      value: updatedArray,
    })
  }

  //splice out the index of the item to be removed from an array
  const removeArrayInput = (index) => {
    let newArr = [...fieldValue.value]
    const wasDefaultService = newArr?.[index]?.['default'] === true
    const serviceType = newArr?.[index]?.['type']
    const isVisitOrService =
      serviceType === 'visitation' || serviceType === 'service'

    newArr.splice(index, 1)

    const hasNoServices = newArr?.length === 0
    const defaultServiceDeleted =
      wasDefaultService && isVisitOrService && !hasNoServices
    defaultServiceDeleted && (newArr[0] = { ...newArr[0], default: true })

    setFieldValue({
      ...fieldValue,
      value: newArr,
    })
  }
  return (
    <Flex full column>
      <Flex full justify="space-between" align="center">
        <Text as="h3" transform="capitalize">
          {title?.split('_').join(' ')}
        </Text>
      </Flex>
      {fieldValue?.value.map(
        (item, i) =>
          (item.type === shortType || !item.type) && (
            <Flex column key={`${item.id}_${i}`}>
              <Divider fill="gray4" margin="24px 0" />
              <Flex align="center" margin="0 0 10px 0">
                <Flex wrap="wrap">
                  {allowedInputs.map((object_key, index) =>
                    index !== 1 ? (
                      <NestedObjectInputs
                        key={`${object_key.field}_${index}`}
                        label={`${object_key.field}`}
                        width="280px"
                        name={`${type}`}
                        index={i}
                        object_key={object_key}
                      />
                    ) : (
                      <React.Fragment key={`${object_key.field}_${index}`}>
                        <Flex justify="flex-end" width="280px">
                          <Flex
                            onClick={() => removeArrayInput(i)}
                            align="center"
                            height="16px"
                          >
                            <Text size="12px" color="gray2" margin="0 8px 0 0">
                              Delete
                            </Text>
                            <Icon
                              icon="deleteOutline"
                              fill="gray2"
                              hoverfill="gray1"
                              height="16px"
                              width="16px"
                              onClick={() => removeArrayInput(i)}
                            />
                          </Flex>
                        </Flex>
                        <NestedObjectInputs
                          key={`${object_key.field}_${index}`}
                          label={`${object_key.field}`}
                          width="280px"
                          name={`${type}`}
                          index={i}
                          object_key={object_key}
                        />
                      </React.Fragment>
                    ),
                  )}
                </Flex>
              </Flex>
              <Divider fill="gray4" margin="24px 0" />
            </Flex>
          ),
      )}
      <Button
        width="280px"
        margin="0 0 40px"
        icon="plus"
        onClick={() => addArrayInput(shortType, case_id)}
      >
        Add {shortType}
      </Button>
    </Flex>
  )
}
