import React from 'react'
import { Flex, Text, Icon } from 'components'
import startCase from 'lodash/startCase'
import capitalize from 'lodash/capitalize'
import * as Menus from '../Menus'
import * as S from './SlideoutMenu.styled'

export default ({
  menu,
  title,
  show: [show, setShow] = [false, () => {}],
  theme,
  themeLayout,
  basePages,
  baseLayout,
  setIsLoading,
  isSearchMenu,
  update,
  ...props
}) => {
  const SelectedMenu = Menus?.[`${menu}Menu`]
  return (
    <S.SlideoutMenu
      top="0"
      left="calc(100% + 1px)"
      position="absolute"
      height="100%"
      width={show ? '470px' : '0'}
      background="white"
      transition=".25s"
      show={show}
      {...props}
    >
      <Flex column minWidth="470px" height="100%">
        <Flex justify="space-between" align="center" margin="24px 16px">
          <Text weight="600" size="16px">
            {!isSearchMenu ? capitalize(startCase(title)) : 'Search Results  '}
          </Text>
          <Icon
            clickable
            onClick={() => setShow(false)}
            icon="exitModal"
            fill="gray1"
            hoverfill="gray2"
          />
        </Flex>
        <S.Content column flex="1">
          {SelectedMenu && (
            <SelectedMenu
              setShow={setShow}
              theme={theme}
              themeLayout={themeLayout}
              basePages={basePages}
              baseLayout={baseLayout}
              setIsLoading={setIsLoading}
              show={show}
              update={update}
            />
          )}
        </S.Content>
      </Flex>
    </S.SlideoutMenu>
  )
}
