import React from 'react'
import startCase from 'lodash/startCase'
import capitalize from 'lodash/capitalize'
import { InsertCategoryItem } from 'components'
import { useRecoilValue } from 'recoil'
import { roleAtom } from 'atoms'

const menuConfig = [
  { name: 'SearchBar', allowedFor: ['fh-user', 'messenger-user'] },
  { name: 'SavedLayouts', allowedFor: ['fh-user', 'messenger-user'] },
  { name: 'PhotosOfDeceased', allowedFor: ['fh-user', 'messenger-user'] },
  { name: 'CustomVersesAndPhrases', allowedFor: ['fh-user', 'messenger-user'] },
  { name: 'DesignAssets', allowedFor: ['messenger-user'] },
]

export default ({
  setMenu,
  showSlideout,
  selectedMenu,
  setSelectedMenu,
  setShowSlideout,
}) => {
  const userRole = useRecoilValue(roleAtom)

  const menus = menuConfig.filter((menu) => {
    return menu.allowedFor.includes(userRole)
  })

  return menus?.map((menu) => (
    <InsertCategoryItem
      selected={showSlideout && selectedMenu === menu.name}
      onClick={() => setMenu(menu.name)}
      key={menu.name}
      menu={menu.name}
      setSelectedMenu={setSelectedMenu}
      setShowSlideout={setShowSlideout}
    >
      {capitalize(startCase(menu.name))}
    </InsertCategoryItem>
  ))
}
