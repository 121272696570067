import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  pointer-events: none;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
`

export const ProofText = styled.div`
  position: absolute;
  transform: rotate(-45deg);
  color: white;
  font-size: 2rem;
  user-select: none;
  pointer-events: none;
  white-space: nowrap;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ index }) => {
    const row = Math.floor(index / 3)
    const col = index % 3
    const top = row * 20 - 60
    const left = col * 20 - 20
    return css`
      top: ${top}%;
      left: ${left}%;
    `
  }}
`
