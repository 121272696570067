import React, { useState, useEffect } from 'react'
import { Flex, PropertiesTabs, PropertyMenu } from 'components'
import { rightMenuAtom } from 'atoms'
import { useSetRecoilState } from 'recoil'

export default () => {
  const [activeTab, setActiveTab] = useState('Elements')
  const setRightMenu = useSetRecoilState(rightMenuAtom)

  useEffect(() => {
    setRightMenu(activeTab)
    //eslint-disable-next-line
  }, [activeTab])

  return (
    <Flex
      column
      justify="space-between"
      height="100%"
      width="250px"
      background="white"
      zindex="1"
      borderLeft="1px solid"
      borderColor="gray4"
    >
      <PropertiesTabs activeTab={[activeTab, setActiveTab]} />
      <PropertyMenu activeTab={activeTab} />
      {/* <GiveFeedback /> */}
      {/*  NOTE: This component will be eventually reused but its functionality will change */}
    </Flex>
  )
}
