import { caseDetailSpan } from '.'
import startCase from 'lodash/startCase'
import { replaceInitialValues } from 'utils'
import { format } from 'date-fns'

export default (editor, caseDetails, key, templates, dateFormat) => {
  caseDetails[key].forEach((service, i) => {
    let html = `<p>
      <p>
        ${caseDetailSpan(`${key}[${i}].location`)}
      </p>
      <p>
        ${caseDetailSpan(`${key}[${i}].address`)}, ${caseDetailSpan(
      `${key}[${i}].city`,
    )}, ${caseDetailSpan(`${key}[${i}].state`)}, ${caseDetailSpan(
      `${key}[${i}].postal_code`,
    )} ${caseDetailSpan(`${key}[${i}].country`)}
      </p>
      <p>
        ${caseDetailSpan(`${key}[${i}].start_time`)} to ${caseDetailSpan(
      `${key}[${i}].end_time`,
    )}
      </p
      </p>
    `
    const content = replaceInitialValues(html, caseDetails)

    templates.push({
      type: 'menuitem',
      text: `${startCase(service.type)}${
        service.date ? ` ${format(service.date, dateFormat)}` : ''
      }`,
      onAction: () => editor.execCommand('mceInsertTemplate', false, content),
    })
  })
}
